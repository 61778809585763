import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { MDModalModule } from '../../modals';
import { LucideAngularModule } from 'lucide-angular';
import { NgbActiveModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-modal',
  standalone: true,
  imports: [CommonModule, LucideAngularModule],
  templateUrl: './delete-modal.component.html',
  styleUrl: './delete-modal.component.scss',
})
export class DeleteModalComponent {
  activeModal = inject(NgbActiveModal);

  @Input() deleteModal: {
    title?: string | null;
    header?: string | null;
    msg?: string | null;
  } = {
    title: null,
    header: null,
    msg: null,
  };

  constructor() {}

  deleteItem() {
    this.activeModal.close('delete');
  }

  close() {
    this.activeModal.close('close');
  }
}
