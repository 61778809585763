<div class="modal-header dark:bg-gray-800">
  <h4 class="modal-title dark:text-white">
    {{ deleteModal.title }}
  </h4>

  <button
    type="button"
    class="btn border border-gray-700 hover:bg-gray-700 dark:text-white ms-auto hover:text-white"
    (click)="close()"
  >
    <lucide-angular name="x" [class]="'inline-block size-5'"></lucide-angular>
  </button>
</div>
<div class="modal-body dark:bg-gray-800">
  <ng-container
    *ngIf="
      deleteModal.header &&
      deleteModal.header !== null &&
      deleteModal.header !== ''
    "
  >
    <h5 class="mb-3 text-base dark:text-white">
      {{ deleteModal.header }}
    </h5>
  </ng-container>
  <ng-container
    *ngIf="
      deleteModal.msg && deleteModal.msg !== null && deleteModal.msg !== ''
    "
  >
    <p class="dark:text-gray-200">
      {{ deleteModal.msg }}
    </p>
  </ng-container>
</div>
<div class="modal-footer dark:bg-gray-800">
  <button
    type="button"
    class="btn border border-gray-700 hover:bg-gray-700 dark:text-white hover:text-white"
    (click)="deleteItem()"
  >
    Delete
  </button>
</div>
